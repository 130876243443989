import { Routes, Route, Link } from "react-router-dom";
import Employees from './components/Employees';
import Home from './components/Home';
import Books from './components/Books';

export default function App() {
  return (
    <>
      <Routes>
            <Route path="/" element={<Home />} />
            <Route path="employee" element={<Employees />} />
            <Route path="book" element={<Books />} />
            <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}