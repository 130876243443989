import React from 'react'
import { Menu } from './Menu'

export const Books = () => {
  return (
    <>
        <article>
            <header className='bg-secondary text-center p-2'>
                <h1>React: Simple Crud App</h1>
            </header>
            <Menu />
            <main className='container-fluid'>
              Under construction
            </main>
        </article>
    </>
  )
}
export default Books