import React from 'react'
import {Container, Nav, NavLink, Navbar} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

export const Menu = () => {  
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
    <>
    
        <Navbar expand="lg" className='bg-dark' data-bs-theme="dark" collapseOnSelect>
            <Container>
                <Navbar.Brand >RRR</Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto'>
                            <NavLink className={splitLocation[1] === "" ? "active" : ""} href='/'>Home</NavLink>
                            <NavLink className={splitLocation[1] === "Employee" ? "active" : ""} href='Employee'>Employee</NavLink>
                            <NavLink className={splitLocation[1] === "Book" ? "active" : ""} href='Book' >Books</NavLink>
                        </Nav>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
    )
}