import React, {  useState } from 'react'
import {  Employee, dummyEmploye } from './Employee.type'
import EmployeeList from './EmployeeList'
import AddEmployee from './AddEmployee'
import ViewEmployee from './ViewEmployee'
import { Menu } from './Menu'


export const Employees = () => {
    const { v4: uuidv4 } = require('uuid');
    const [employeeList, setEmployeeList] = useState( dummyEmploye as Employee[])
    const [showPage, setShownPage] = useState(false)
    const [showDetail, setShownDetail] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [employeeDetail, setEmployeeDetail] = useState({} as Employee | undefined)
    

    const closeShowPage = () => {
        setShownPage(false)
    }
    const closeShowDetail = () => {
        setShownDetail(false)
    }
    
    const saveEmployee = (e: Employee) => {
        let lista = [...employeeList]
        e.id = uuidv4()
        lista.push(e)
        setEmployeeList(lista)
        setShownPage(false)
    }
    const onView = (id: string) => {
        let employee = employeeList.find(e => e.id === id) 
        setEmployeeDetail(employee)
        setIsEdit(false)
        setShownDetail(true)
    }
    const onEdit = (id: string) => {
        let employee = employeeList.find(e => e.id === id) 
        setEmployeeDetail(employee)
        setIsEdit(true)
        setShownDetail(true)
    }
    const updateEmployee = (e: Employee) => {
        let lista = [...employeeList]
        const index = lista.findIndex(emp => emp.id === e.id)
        lista[index] = e
        setEmployeeList(lista)
        setIsEdit(false)
        setShownDetail(false)

    }
    const onDelete = (id: string) => {
        let lista = [...employeeList]
        const index = lista.findIndex(emp => emp.id === id)
        lista.splice(index)
        setEmployeeList(lista)
        
    }

    
    return (
    
    <article>
        <header className='bg-secondary text-center p-2'>
            <h1>React: Simple Crud App</h1>
        </header>
        <Menu />
        <main className='container mt-2'>
            <button type='button' className='btn btn-primary' onClick={() => setShownPage(true)}>Add</button>
            
            <hr/>
            <EmployeeList list={employeeList} onView={onView} onEdit={onEdit} onDelete={onDelete} />
            {
                showPage ? <AddEmployee showModal={showPage} saveEmployee={saveEmployee} closeShowPage={closeShowPage} /> : ''
            }
            {
                showDetail ? <ViewEmployee showModal={showDetail} isEdit={isEdit} employee={employeeDetail} updateEmployee={updateEmployee} closeShowDetail={closeShowDetail}/> :''
            }
            
        </main>
        
    </article>
  )
}
export default Employees
