export type Employee = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    observation?: string
}

const { v4: uuidv4 } = require('uuid');

export const dummyEmploye : Employee[] = [
    {
        id: uuidv4(),
        firstName: 'Pepito',
        lastName: 'Përez',
        email: 'arroba@.sddd.com',
        observation: 'el texto de la observación'
    },
    {
        id: uuidv4(),
        firstName: 'Maria',
        lastName: 'Muñoz',
        email: 'email@dds.com'
    }
]

export type AlertaType = {
    variant: string,
    titulo: string,
    texto: string,
    isOpen: boolean,
    setClose: () => void
}