import React, { useState } from 'react'
import { Employee } from './Employee.type';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';


type props = {
    closeShowDetail: () => void,
    showModal: boolean,
    employee: Employee | undefined,
    updateEmployee: (e: Employee) => void,
    isEdit: boolean
}

const ViewEmployee = (props: props) => {

    const {closeShowDetail, employee, showModal, isEdit, updateEmployee} = props;
    const [emp, setEmp] = useState(employee as Employee)
    const close = () => {
        closeShowDetail()
    }
    
    const actualizar = () => {
        updateEmployee(emp)
    }
    
    const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const empleado = {
            ...emp,
            [e.target.name] : e.target.value
        }
        setEmp(empleado)
    }
    
    return (
        <>
            <Modal show={showModal} >
                <ModalHeader>
                    <h3>View Employee</h3>
                    <button className='btn btn-sm btn-danger' onClick={close}>x</button>
                </ModalHeader>
                <ModalBody>
                    <div className='form-group'>
                        <label className='form-label'>First Name</label>
                        <input className='form-control' autoComplete='off' name='firstName' type='text' onChange={handleChange} value={emp.firstName}></input>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Last Name</label>
                        <input className='form-control' autoComplete='off' name='lastName' type='text' onChange={handleChange} value={emp.lastName}></input>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Email</label>
                        <input className='form-control' autoComplete='off' name='email' type='text' onChange={handleChange} value={emp.email}></input>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Observation</label>
                        <textarea className='form-control' rows={3} name="observation"  onChange={handleChange} value={emp?.observation}></textarea>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {
                        isEdit ? <button className='btn btn-primary' onClick={actualizar}>Guardar</button> : ''
                    }
                    <button className='btn btn-danger' onClick={close}>Cancelar</button>
                </ModalFooter>
            </Modal>
            
        </>
  )
}

export default ViewEmployee