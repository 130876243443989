import React from 'react'
import { Employee } from './Employee.type'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

type Props = {
  list: Employee[],
  onView:  (id: string) => void,
  onEdit:  (id: string) => void
  onDelete:  (id: string) => void
}

function EmployeeList(props: Props) {

  const {list, onView,onEdit,onDelete} = props;
  const onClickView = (id: string) =>{
    onView(id)
  }
  const onClickEdit = (id: string) =>{
    onEdit(id)
  }
  const onClickDelete= (id: string) =>{
    
    confirmAlert({
      title: 'Delete employee ',
      message: 'Are you sure to delete this employee ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onDelete(id)
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    });
  }
  return (
    <>
      <h2>Employee List</h2>
      <table className='table table-responsive table-striped table-hover table-fixed'>
        <thead >
          <tr key={0}>
            <th>Name</th><th>Last Name</th><th>Email</th><th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {
          list.map( e => {
            return (
              <tr key={e.id}>
                <td >{e.firstName}</td>
                <td >{e.lastName}</td>
                <td >{e.email}</td>
                <td > 
                  <div>
                    <button className='btn btn-sm btn-info me-2' onClick={() => onClickView(e.id)} type='button'>View</button>
                    <button className='btn btn-sm btn-primary me-2' onClick={() => onClickEdit(e.id)} type='button'>Edit</button>
                    <button className='btn btn-sm btn-danger' onClick={() => onClickDelete(e.id)} type='button'>Delete</button>
                  </div>
                </td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </>
  )
}

export default EmployeeList