import { Carousel } from 'react-bootstrap'
import { Menu } from './Menu'
const Home = () => {
    

    return (
        <article>
            <header className='bg-secondary text-center p-2'>
                <h1>React: Simple Crud App</h1>
            </header>
            <Menu />
            <main className='container-fluid'>
               <Carousel data-bs-theme="dark">
                    <Carousel.Item className='text-center'>
                        <img className='w-40 ' src='logo512.png' ></img>
                        <Carousel.Caption>
                            <h3>Welcome</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className='text-center'>
                        <img className='w-40' src='logo512.png' ></img>
                        <Carousel.Caption>
                            <h3>To</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className='text-center'>
                    <img className='w-40 ' src='logo512.png' ></img>
                        <Carousel.Caption>
                            <h3>AU</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
               </Carousel>
            </main>
        </article>
    )
}

export default Home
