import React, { useState } from 'react'
import { Employee } from './Employee.type';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';


type props = {
    closeShowPage: () => void,
    saveEmployee: (e:Employee) => void,
    showModal: boolean
}

const AddEmployee = (props: props) => {
    const [employee, setEmployee] = useState({id:'',firstName: '',lastName:'',email:''} as Employee)
    const {closeShowPage, saveEmployee, showModal} = props;

    const close = () => {
        closeShowPage()
    }
    const guardar = () => {
        saveEmployee(employee)
    }   

    const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const emp = {
            ...employee,
            [e.target.name] : e.target.value
        }
        setEmployee(emp)
    }
    
    
    return (
        <>
            <Modal show={showModal} >
                <ModalHeader>
                    <h3>Add Employee</h3>
                    <button className='btn btn-sm btn-danger' onClick={close}>x</button>
                </ModalHeader>
                <ModalBody>
                    <div className='form-group'>
                        <label className='form-label'>First Name</label>
                        <input className='form-control' autoComplete='off' name='firstName' type='text' onChange={onChangeHandle} value={employee.firstName}></input>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Last Name</label>
                        <input className='form-control' autoComplete='off' name='lastName' type='text' onChange={onChangeHandle} value={employee.lastName}></input>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Email</label>
                        <input className='form-control' autoComplete='off' name='email' type='text' onChange={onChangeHandle} value={employee.email}></input>
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>Observation</label>
                        <textarea className='form-control' name='observation' rows={3} onChange={onChangeHandle} value={employee.observation}></textarea>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary' onClick={guardar}>Guardar</button>
                    <button className='btn btn-danger' onClick={close}>Cancelar</button>
                </ModalFooter>
            </Modal>
            
        </>
  )
}

export default AddEmployee